import createCache from '@emotion/cache';
import {
  CacheProvider,
  ThemeProvider as StyledThemeProvider
} from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import BranchConnectionVerification from 'BranchConnectionVerification';
import { AryAlertTypes } from 'framework/base/components/AryAlert';
import PreventZoomModal from 'framework/base/components/PreventZoomModal';
import { TutorialProvider } from 'framework/base/components/tutorial/TutorialContextProvider';
import {
  GET_DESCRIPTION_TEMPLATES,
  GET_SYSTEM_SETTINGS
} from 'framework/base/constants/aryGraphqlSchemas';
import arySystemSettings, {
  fontSizes
} from 'framework/base/constants/arySystemSettings';
import DescriptionTemplatesContext from 'framework/base/contexts/DescriptionTemplatesContext';
import LayoutTypeContext from 'framework/base/contexts/LayoutTypeContext';
import ShowCurrenciesContextProvider from 'framework/base/contexts/ShowCurrenciesContextProvider';
import SystemSettingsContext from 'framework/base/contexts/SystemSettingsContext';
import useAryAlert from 'framework/base/hooks/useAryAlert';
import { useAryLazyQuery } from 'framework/base/hooks/useAryGraphql';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import useAryUsername from 'framework/base/hooks/useAryUsername';
import usePreventZoom from 'framework/base/hooks/usePreventZoom';
import useTemplateParsing from 'framework/base/hooks/useTemplateParsing';
import THEMES from 'framework/base/theme/constants';
import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {
  connectWebSocket,
  disconnectWebSocket
} from 'redux/actions/websocketActions';
import {
  setDoBootstrap,
  setErrors,
  setNoVersionError,
  updateUserCurrencies
} from 'redux/slices/systemSlice';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler,
  authService,
  useAuth
} from 'spec';
import { prefixer } from 'stylis';
import AdvancedPanelContext from 'framework/base/contexts/AdvancePanelContext';
import rtlPlugin from 'stylis-plugin-rtl';
import './assets/fontYekanBakh.css';
import createTheme from './framework/base/theme/index';
import './language/translation';
import appRoutes, { settingsRoutes } from './routes';

const AryNoVersionModal = lazy(() =>
  import('framework/base/components/AryNoVersionModal')
);

const { ApplicationPaths } = ApiAuthorizationConstants;

const DEFAULT_LANGUAGE = 'fa';

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin]
});

const DirectionProvider = ({ rtl = false, children }) => {
  if (!rtl) {
    return children;
  }
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function lazyRoutes(routes, layoutType, userName) {
  if (!routes || routes.length === 0 || !userName) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              path={`/${userName}/${route.path}`}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType, userName)}
        </Route>
      ))}
    </>
  );
}

const contentTypes = {
  loading: 0,
  settings: 1,
  application: 2
};

const App = () => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const reduxStore = useStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const langDirection = t('dir');
  const layoutType = useAryLayoutType();
  const [AryAlert, showAlert] = useAryAlert({
    onClose: () => {
      reduxStore.dispatch(setErrors([]));
    }
  });
  const intervalId = useRef(null);
  const versionExpireTimeRef = useRef(null);
  document.body.dir = langDirection;
  const userName = useAryUsername();

  // const [userName, setUserName] = useState('');
  // const aryauth = JSON.parse(localStorage.getItem('aryauth'));

  // useEffect(() => {
  //   if (!aryauth?.tkrs?.access_token) return;

  //   authService.getUserName().then((data) => setUserName(data));
  // }, [userName, aryauth?.tkrs?.access_token]);

  // Define the WebSocket URL
  const webSocketUrl = 'ws://localhost:5188/ws';

  const hasProfitAndLossStatementViewAccess = auth?.ProfitAndLossStatement;
  const hasSubmitOutgoingDraftAccess = auth?.OutgoingDraftRE;
  const hasSubmitIncomingDraftAccess = auth?.IncomingDraftRE;
  const hasSubmitCashTransactionAccess = auth?.CashTransactionRE;
  const hasSubmitTransferBetweenAccountsAccess =
    auth?.TransferBetweenAccountsRE;
  const hasSubmitBankTransactionAccess = auth?.BankTransactionRE;
  const hasViewDraftDocumentsListAccess = auth?.DraftDocumentsList;
  const hasViewBranchesListAccess = auth?.BranchesList;

  const [contentStatus, setContentStatus] = useState(contentTypes.loading);
  const [systemSettings, setSystemSettings] = useState({});
  const [openAdvancePanelModal, setOpenAdvancePanelModal] = useState(false);
  const [noVersionModalOpen, setNoVersionModalOpen] = useState(false);
  const [advancePanelbuttonRect, setAdvancePanelButtonRect] = useState(null);
  const advancePanelButtonRef = useRef(null);

  const [modalInfo, setModalInfo] = useState({ show: false, x: 0, y: 0 });

  const showPreventZoomModal = ({ type, x, y }) => {
    if (type === 'scroll') {
      setModalInfo({ show: true, x: x || 0, y: y || 0 }); // Ensure x, y coordinates
    } else if (type === 'keyboard') {
      setModalInfo({
        show: true,
        x: window.innerWidth / 2, // Center modal for keyboard action
        y: window.innerHeight / 2
      });
    }
  };

  const closeModal = () => {
    setModalInfo({ show: false, x: 0, y: 0 });
  };

  usePreventZoom(showPreventZoomModal);

  const handleOpenAdvanceModal = () => {
    if (advancePanelButtonRef.current) {
      const rect = advancePanelButtonRef.current.getBoundingClientRect();
      setAdvancePanelButtonRect(rect);
    }
    setOpenAdvancePanelModal(true);
  };
  const handleAdvancePanelModalClose = () => setOpenAdvancePanelModal(false);

  const [
    getSystemSettings,
    { data: getSystemSettingsQueryData, loading: getSystemSettingsLoading }
  ] = useAryLazyQuery({
    gqlConfig: GET_SYSTEM_SETTINGS,
    items: `
      systemLanguage
      mainCurrency
      isGregorian
      defaultPhonePrefix
      profitAndLossAccuracy
      documents {
        documentTitle
        isManual
        issuingOrganization
      }
      userCurrencies {
        currencyCode
        decimalPlaces
        dollarRate
        isDivision
      }
      fileId
      exchangeName
      address
      slogan
      descriptions
      groupNames
      phoneNumbers {
        countryCode
        phoneNumber
        communicationWays
      }
      bankInfos {
        accountOwnerName
        bankAccountNumber
        cardNumber
        iBan
      }
      fontSize
      isDarkMode
      timeZone
      showCurrencyByTitle
      isDigitsLatin
      showReportOnWeb
      defaultSendWhatsApp
      defaultUpdateCreditLimit
    `
  });

  const [getDescriptionTemplates, { data: descriptionTemplates }] =
    useAryLazyQuery({
      gqlConfig: GET_DESCRIPTION_TEMPLATES,
      options: {
        fetchPolicy: 'cache-first'
      },
      items: `
        id
        body
      `
    });

  const appTheme = createTheme(
    getSystemSettingsQueryData?.isDarkMode ? THEMES.DARK : THEMES.DESIGNER,
    getSystemSettingsQueryData,
    langDirection,
    layoutType
  );

  async function bootstrap() {
    const resp = await getSystemSettings();
    await getDescriptionTemplates(
      resp?.data?.systemLanguage || DEFAULT_LANGUAGE
    );
    await setFontSize(resp?.data);
    if (!intervalId.current) {
      authService.checkSession();
      intervalId.current = setInterval(() => {
        authService.checkSession();
      }, 30000);
    }
  }

  async function setFontSize(data) {
    const htmlTag = document.getElementsByTagName('html')[0];
    switch (data?.fontSize) {
      case 1: // small
        htmlTag.style.fontSize = fontSizeCalculation(0.8);
        break;
      case 2: // medium
        htmlTag.style.fontSize = fontSizeCalculation(1);
        break;
      case 3: // large
        htmlTag.style.fontSize = fontSizeCalculation(1.2);
        break;
      default:
        htmlTag.style.fontSize = '16px';
    }
  }
  const fontSizeCalculation = (coefficient) => {
    const minFontSize = 13;
    const maxFontSize = 36;

    // Define the base dimensions for the calculation
    const baseWidth = 1440; // base width for 16px font size
    const baseHeight = 1024; // base height for 16px font size
    const baseFontSize = 16; // base font size for the base dimensions

    // Get the current viewport dimensions
    const vw = window.innerWidth;
    const vh = window.innerHeight;

    // make width less important
    const ratio = layoutType === 'laptop' ? 0.9 : 0.5;

    // Use the geometric mean of the scale factors
    const scaleFactor =
      Math.sqrt((vw * ratio) ** 2 + vh ** 2) /
      Math.sqrt((baseWidth * ratio) ** 2 + baseHeight ** 2);

    // Calculate the new font size based on the scale factor and coefficient
    let newFontSize = baseFontSize * scaleFactor * coefficient;

    newFontSize = Math.max(minFontSize, Math.min(newFontSize, maxFontSize));

    // Round the final font size to top
    return `${Math.floor(newFontSize)}px`;
  };

  const parseDescription = useTemplateParsing(descriptionTemplates);

  function hasSystemSettings(settings) {
    if (!settings) return false;
    if (
      !settings.mainCurrency ||
      !settings.profitAndLossAccuracy ||
      !settings.userCurrencies?.length ||
      !settings.exchangeName ||
      !settings.groupNames?.length
    ) {
      return false;
    }
    return true;
  }

  function getBaseRoute() {
    switch (contentStatus) {
      case contentTypes.loading:
        return <Route path="/*" element={<AryLoadingAnimation />} />;
      case contentTypes.settings:
        return (
          <>
            <Route path="/*" element={<Navigate replace to="/welcome" />} />
            {lazyRoutes(settingsRoutes, layoutType, userName)}
          </>
        );
      case contentTypes.application: {
        if (!userName || !descriptionTemplates?.length || !auth) {
          return <Route path="/*" element={<AryLoadingAnimation />} />;
        }
        return (
          <>
            <Route
              path="/system-settings"
              element={
                <Navigate
                  replace
                  to={
                    hasProfitAndLossStatementViewAccess
                      ? `${userName}/dashboard/bi-dashboard`
                      : `${userName}/dashboard/customer/list`
                  }
                />
              }
            />
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={
                    hasProfitAndLossStatementViewAccess
                      ? `${userName}/dashboard/bi-dashboard`
                      : `${userName}/dashboard/customer/list`
                  }
                />
              }
            />
            {lazyRoutes(appRoutes, layoutType, userName)}
          </>
        );
      }
      default:
        return null;
    }
  }

  reduxStore.subscribe(() => {
    const { errors } = reduxStore.getState().systemReducer;
    if (errors.length) {
      showAlert(
        AryAlertTypes.ERROR,
        parseDescription(errors[0].templateId, errors[0].metadata)
      );
    }
  });

  useEffect(() => {
    if (window.location.pathname !== ApplicationPaths.LoginCallback) {
      bootstrap();
    }

    reduxStore.subscribe(() => {
      const { noVersionError, doBootstrap } =
        reduxStore.getState().systemReducer;
      if (noVersionError) {
        setNoVersionModalOpen(true);
        reduxStore.dispatch(setNoVersionError(false));
      }
      if (doBootstrap) {
        reduxStore.dispatch(setDoBootstrap(false));
        bootstrap();
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!getSystemSettingsLoading && getSystemSettingsQueryData) {
        if (hasSystemSettings(getSystemSettingsQueryData)) {
          i18n.changeLanguage(
            getSystemSettingsQueryData[arySystemSettings.systemLanguage] || 'fa'
          );
          setSystemSettings(getSystemSettingsQueryData);
          setContentStatus(contentTypes.application);
          window.paths.rptf =
            layoutType === 'mobile'
              ? window.paths.rptw
              : getSystemSettingsQueryData[arySystemSettings.showReportOnWeb]
              ? window.paths.rptw
              : window.paths.rptd;
          reduxStore.dispatch(updateUserCurrencies(true));
          const isVersionValid = await authService.isVersionValid();
          if (!isVersionValid) {
            if (
              window.location.pathname !==
              `/${ApplicationPaths.BranchConnection}`
            ) {
              reduxStore.dispatch(setNoVersionError(true));
            }
          }
        } else {
          const versionExpireTime = await authService.getVersionExpireTime();
          versionExpireTimeRef.current = versionExpireTime;
          if (new Date().getTime() < +versionExpireTime) {
            if (
              window.location.pathname ===
              `/${ApplicationPaths.BranchConnection}`
            ) {
              setContentStatus(contentTypes.application);
            } else {
              setContentStatus(contentTypes.settings);
            }
          } else if (
            window.location.pathname === `/${ApplicationPaths.BranchConnection}`
          ) {
            setContentStatus(contentTypes.application);
          } else {
            reduxStore.dispatch(setNoVersionError(true));
          }
        }
      }
    })();
  }, [getSystemSettingsQueryData]);

  useEffect(
    () => () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    },
    []
  );

  useEffect(() => {
    if (contentStatus === contentTypes.application) {
      document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.key === 'F5') {
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
        }
        if (event.ctrlKey && event.shiftKey) {
          switch (event.code) {
            // To Do : Change this
            // case 'Digit0':
            //   navigate(`../${userName}/dashboard/bi-dashboard`);
            //   break;
            case 'Digit1': {
              if (hasViewDraftDocumentsListAccess) {
                navigate(`../${userName}/dashboard/draft/orders/list`);
              }
              break;
            }
            case 'Digit2':
              navigate(`../${userName}/dashboard/customer/list`, {
                state: { shouldGoToFirstAccount: true }
              });
              break;
            case 'Digit3': {
              if (hasViewBranchesListAccess) {
                navigate(`../${userName}/dashboard/global-ledger/branch/list`, {
                  state: { shouldGoToFirstAccount: true }
                });
              }
              break;
            }
            case 'Digit4': {
              if (hasSubmitOutgoingDraftAccess) {
                event.preventDefault();
                navigate(`../${userName}/dashboard/draft/submission/outgoing`);
              }
              break;
            }
            case 'Digit5': {
              if (hasSubmitIncomingDraftAccess) {
                event.preventDefault();
                navigate(`../${userName}/dashboard/draft/submission/incoming`);
              }
              break;
            }
            case 'Digit6': {
              if (hasSubmitCashTransactionAccess) {
                navigate(
                  `../${userName}/dashboard/journal-entries/cash-transaction`
                );
              }
              break;
            }
            case 'Digit7': {
              if (hasSubmitBankTransactionAccess) {
                navigate(
                  `../${userName}/dashboard/journal-entries/bank-transaction`
                );
              }
              break;
            }
            case 'Digit8': {
              if (hasSubmitTransferBetweenAccountsAccess) {
                navigate(
                  `../${userName}/dashboard/journal-entries/transfer-between-accounts`
                );
              }
              break;
            }
            case 'Digit9': {
              navigate(
                `../${userName}/dashboard/journal-entries/trade-currency/buy`
              );
              break;
            }
            default:
              break;
          }
        }
        if (event.ctrlKey && event.shiftKey && event.code === 'KeyF') {
          handleOpenAdvanceModal();
        }
      });
    }
  }, [contentStatus]);

  // Initialize WebSocket connection when the component mounts
  useEffect(() => {
    dispatch(connectWebSocket(webSocketUrl));

    return () => {
      dispatch(disconnectWebSocket());
    };
  }, [dispatch]);

  const advancedPanelContextValue = useMemo(
    () => ({
      openAdvancePanelModal,
      handleAdvancePanelModalClose,
      advancePanelbuttonRect,
      handleOpenAdvanceModal,
      advancePanelButtonRef
    }),
    [
      openAdvancePanelModal,
      handleAdvancePanelModalClose,
      advancePanelbuttonRect,
      handleOpenAdvanceModal,
      advancePanelButtonRef
    ]
  );
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${t('Moneyx')}`}
        defaultTitle={`${t('Moneyx')} - ${t('Exchange Accounting Software')}`}
      />
      <SystemSettingsContext.Provider value={systemSettings}>
        <AdvancedPanelContext.Provider value={advancedPanelContextValue}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
              <StyledThemeProvider theme={appTheme}>
                <DirectionProvider rtl={langDirection === 'rtl'}>
                  <LayoutTypeContext.Provider value={layoutType}>
                    <DescriptionTemplatesContext.Provider
                      value={descriptionTemplates}
                    >
                      <TutorialProvider>
                        <ShowCurrenciesContextProvider>
                          <Routes>
                            {getBaseRoute()}
                            <Route
                              exact
                              path={ApplicationPaths.LoginCallback}
                              element={<LoginCallbackHandler />}
                            />
                            <Route
                              exact
                              path={ApplicationPaths.BranchConnection}
                              element={
                                <BranchConnectionVerification
                                  applicationContentType={
                                    contentTypes.application
                                  }
                                  contentStatus={contentStatus}
                                />
                              }
                            />
                          </Routes>
                          {AryAlert}
                          {noVersionModalOpen && (
                            <Suspense fallback="">
                              <AryNoVersionModal
                                open={noVersionModalOpen}
                                handleClose={() => setNoVersionModalOpen(false)}
                                showConnectedBranchesButton
                                handleOnConnectedBranchesButtonClicked={() => {
                                  if (!versionExpireTimeRef.current) {
                                    setSystemSettings({
                                      mainCurrency: 'USD',
                                      fontSize: fontSizes.medium,
                                      isDigitsLatin: false,
                                      defaultPhonePrefix: '98',
                                      timeZone: 'Asia/Tehran',
                                      isGregorian: false,
                                      showCurrencyByTitle: true,
                                      profitAndLossAccuracy: 15,
                                      documents: [
                                        {
                                          documentTitle: '',
                                          isManual: false,
                                          issuingOrganization: ''
                                        }
                                      ],
                                      isDarkMode: false,
                                      file: undefined,
                                      exchangeName: '',
                                      phoneNumbers: [
                                        {
                                          phoneNumber: '',
                                          countryCode: '98',
                                          communicationWays: [1]
                                        }
                                      ],
                                      address: '',
                                      slogan: '',
                                      descriptions: [''],
                                      groupNames: [
                                        t('General'),
                                        t('Banking'),
                                        t('Cost')
                                      ],
                                      userCurrencies: [
                                        {
                                          currencyCode: 'USD',
                                          decimalPlaces: 2,
                                          dollarRate: 1,
                                          isDivision: false
                                        }
                                      ],
                                      showReportOnWeb: true
                                    });
                                  }
                                  setContentStatus(contentTypes.application);
                                }}
                              />
                            </Suspense>
                          )}
                          {showPreventZoomModal && (
                            <Suspense fallback="">
                              <PreventZoomModal
                                x={modalInfo.x}
                                y={modalInfo.y}
                                open={modalInfo.show}
                                onClose={closeModal}
                                data={getSystemSettingsQueryData}
                              />
                            </Suspense>
                          )}
                        </ShowCurrenciesContextProvider>
                      </TutorialProvider>
                    </DescriptionTemplatesContext.Provider>
                  </LayoutTypeContext.Provider>
                </DirectionProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </AdvancedPanelContext.Provider>
      </SystemSettingsContext.Provider>
    </HelmetProvider>
  );
};

export default App;
