import React, { createContext, useContext, useMemo, useState } from 'react';
import Joyride from 'react-joyride';
import { useStore } from 'react-redux';
import { updateTutorialState } from 'redux/slices/systemSlice';
import TooltipComponent from './TutorialTooltipComponent';

const TutorialContext = createContext();

export const TutorialProvider = ({ children }) => {
  const reduxStore = useStore();
  const [steps, setSteps] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [currentTutorialKey, setCurrentTutorialKey] = useState(null);

  // Start a tutorial
  const startTutorial = (tutorialSteps, tutorialKey) => {
    setSteps(tutorialSteps);
    setIsRunning(true);
    setCurrentTutorialKey(tutorialKey);
    reduxStore.dispatch(
      updateTutorialState({ name: tutorialKey, status: 'running' })
    );
  };

  // Stop a tutorial
  const stopTutorial = (tutorialKey) => {
    setIsRunning(false);
    setSteps([]);
    reduxStore.dispatch(
      updateTutorialState({ name: tutorialKey, status: 'stopped' })
    );
  };

  // Update tutorial status in localStorage
  const saveTutorialStatus = (tutorialKey, status) => {
    const hasSeenTutorial = status === 'finished' || status === 'skipped';
    const updatedTutorial = { tutorial: tutorialKey, hasSeenTutorial };

    // Retrieve and update tutorials in localStorage
    const storedTutorials = JSON.parse(localStorage.getItem('tutorials')) || [];
    const tutorialIndex = storedTutorials.findIndex(
      (t) => t.tutorial === tutorialKey
    );

    if (tutorialIndex !== -1) {
      storedTutorials[tutorialIndex].hasSeenTutorial = hasSeenTutorial;
    } else {
      storedTutorials.push(updatedTutorial);
    }

    localStorage.setItem('tutorials', JSON.stringify(storedTutorials));
  };

  const contextValue = useMemo(
    () => ({
      startTutorial
    }),
    []
  );

  return (
    <TutorialContext.Provider value={contextValue}>
      {children}
      <Joyride
        steps={steps}
        run={isRunning}
        continuous
        // showProgress
        showSkipButton
        disableBeacon
        disableScrolling
        tooltipComponent={(props) => (
          <TooltipComponent {...props} steps={steps} />
        )}
        callback={({ status, action }) => {
          if (status === 'finished' || action === 'skip') {
            saveTutorialStatus(currentTutorialKey, status);
            stopTutorial(currentTutorialKey);
          }
        }}
        styles={{
          spotlight: {
            position: 'absolute',
            zIndex: 10000
          },
          overlay: {
            overflow: 'hidden !important',
            fontFamily: 'YekanBakhFaNum'
          },
          options: {
            zIndex: 10000,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            fontFamily: 'YekanBakhFaNum'
          },
          tooltip: {
            backgroundColor: '#fff',
            borderRadius: '5rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '1rem',
            textAlign: 'center',
            maxWidth: '20rem',
            fontFamily: 'YekanBakhFaNum',
            fontSize: '1rem'
          }
        }}
        locale={{
          back: 'Return',
          close: 'Close',
          last: 'End',
          next: 'Next',
          skip: 'Skip'
        }}
      />
    </TutorialContext.Provider>
  );
};

export const useTutorialContext = () => useContext(TutorialContext);
