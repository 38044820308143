import { useEffect, useState } from 'react';
import { authService } from 'spec';

const useAryUsername = () => {
  const [userName, setUserName] = useState('');
  const aryauth = JSON.parse(localStorage.getItem('aryauth') || 'null');

  useEffect(() => {
    if (!aryauth?.tkrs?.access_token) return;

    authService.getUserName().then((data) => setUserName(data));
  }, [aryauth?.tkrs?.access_token]);

  return userName;
};

export default useAryUsername;
