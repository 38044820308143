import styled from '@emotion/styled';
import { Card as MuiCard, CardContent as MuiCardContent } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { spacing } from '@mui/system';
import React from 'react';

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
  height: ${(props) => props.height};
`;

const Card = MuiStyled(MuiCard)`
  ${spacing};
  box-shadow: none;
  height: inherit;
`;

const CardContent = MuiStyled(MuiCardContent)`
  padding: ${(props) => props.theme.padding.aryCard};
  height: inherit;
`;

const AryCard = ({
  children,
  minHeight,
  height,
  padding,
  cardContentSx,
  ...others
}) => (
  <Shadow height={height}>
    <Card variant="outlined" {...others}>
      <CardContent sx={{ minHeight, padding, ...cardContentSx }}>
        {children}
      </CardContent>
    </Card>
  </Shadow>
);

export default AryCard;
