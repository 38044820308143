import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AryCard from '../AryCard';

function TutorialTooltipComponent({
  backProps,
  continuous,
  index,
  primaryProps,
  skipProps,
  step,
  steps,
  tooltipProps
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const progress = ((index + 1) / steps.length) * 100;
  const prevStep = useRef(index);

  useEffect(() => {
    prevStep.current = index;
  }, [index]);

  return (
    <AryCard
      className="tooltip__body"
      cardContentSx={{
        maxWidth: '26rem',
        padding: '1.5rem',
        animation: 'fadeIn 0.5s ease-in-out',
        zIndex: 99999
      }}
      {...tooltipProps}
    >
      <Grid container spacing={2}>
        {/* Top Controls */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" color="text.secondary">
            {index + 1} / {steps.length}
          </Typography>
          <IconButton
            sx={{
              color: theme.palette.grey[700],
              transition: 'transform 0.3s ease, color 0.3s ease',
              '&:hover': {
                transform: 'rotate(90deg)',
                color: theme.text.red.color
              }
            }}
            {...skipProps}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* Content */}
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            animation: 'slideIn 0.6s ease-in-out'
          }}
        >
          {step.title && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: theme.palette.text.primary,
                marginBottom: '0.5rem'
              }}
            >
              {t(step.title)}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
              lineHeight: 1.6
            }}
          >
            {t(step.content)}
          </Typography>
        </Grid>

        {/* Progress Bar */}
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: theme.palette.grey[300],
              '& .MuiLinearProgress-bar': {
                transition: 'width 0.5s ease-in-out'
              }
            }}
          />
        </Grid>

        {/* Footer Buttons */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {index > 0 && (
            <Button
              size="small"
              startIcon={
                theme.direction === 'rtl' ? (
                  <NavigateNextIcon />
                ) : (
                  <NavigateBeforeIcon />
                )
              }
              sx={{
                animation: 'slideLeft 0.8s ease-in-out'
              }}
              {...backProps}
            >
              {t(backProps.title) || 'Back'}
            </Button>
          )}
          {continuous && (
            <Button
              size="small"
              variant="contained"
              endIcon={
                theme.direction === 'rtl' ? (
                  <NavigateBeforeIcon />
                ) : (
                  <NavigateNextIcon />
                )
              }
              sx={{
                animation: 'slideRight 0.8s ease-in-out'
              }}
              {...primaryProps}
            >
              {t(primaryProps.title) || 'Next'}
            </Button>
          )}
        </Grid>
      </Grid>

      {/* CSS Animations */}
      <style>
        {`
          .__floater,
          .__floater__open {
            will-change: unset !important;
            filter: none !important;
            background: none !important;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes slideIn {
            from {
              transform: translateY(20px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          @keyframes slideLeft {
            from {
              opacity: 0;
              transform: translateX(-25px);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }

          @keyframes slideRight {
            from {
              opacity: 0;
              transform: translateX(25px);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }
        `}
      </style>
    </AryCard>
  );
}

export default React.memo(TutorialTooltipComponent);
