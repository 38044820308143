import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

function getRoutesArray(gqlResult, routesNum) {
  let currentPath = gqlResult.definitions[0];
  const routes = [];
  for (let i = 0; i < routesNum; i++) {
    const path = currentPath.selectionSet.selections[0];
    currentPath = path;
    routes[i] = path.name.value;
  }
  return routes;
}

function getPureData(data, routes) {
  if (!data) return undefined;
  let pureData = data;
  for (let i = 0; i < routes.length; i++) {
    pureData = pureData[routes[i]];
  }
  return pureData;
}

const useAryQuery = ({ gqlConfig, variables, items, options, skip }) => {
  const gqlResult = gql`
    ${gqlConfig.gqlSchema(items)}
  `;
  const routes = getRoutesArray(gqlResult, gqlConfig.routesNum);
  const propertyName = gqlResult.definitions[0].variableDefinitions.length
    ? gqlResult.definitions[0].variableDefinitions[0].variable.name.value
    : gqlResult.definitions[0].name.value;
  const queryResult = useQuery(gqlResult, {
    variables: { [propertyName]: variables },
    fetchPolicy: 'no-cache',
    skip,
    ...options
  });
  return { ...queryResult, data: getPureData(queryResult.data, routes) };
};

const useAryLazyQuery = ({ gqlConfig, items, options, fetchPolicy }) => {
  const gqlResult = gql`
    ${gqlConfig.gqlSchema(items)}
  `;
  const routes = getRoutesArray(gqlResult, gqlConfig.routesNum);
  const [func, queryResult] = useLazyQuery(gqlResult, {
    fetchPolicy: fetchPolicy || 'no-cache',
    ...options
  });

  const extractPropertyName = (definitions) => {
    const variables = definitions[0].variableDefinitions;
    if (variables.length === 0) return definitions[0].name.value;
    if (variables.length === 1) return variables[0].variable.name.value;
    return variables.map((v) => v.variable.name.value);
  };

  const finalFunc = async (data) => {
    const propertyName = extractPropertyName(gqlResult.definitions);
    const variables = Array.isArray(propertyName)
      ? propertyName.reduce((acc, key) => {
          acc[key] = data[key];
          return acc;
        }, {})
      : { [propertyName]: data };

    const response = await func({ variables });
    return {
      ...response,
      data: response.data && getPureData(response.data, routes)
    };
  };
  return [
    finalFunc,
    { ...queryResult, data: getPureData(queryResult.data, routes) }
  ];
};

const useAryMutation = (gqlConfig, options) => {
  const gqlResult = gql`
    ${gqlConfig.gqlSchema}
  `;
  const routes = getRoutesArray(gqlResult, gqlConfig.routesNum);
  const [func, mutationResult] = useMutation(gqlResult, options);
  const finalFunc = (data) => {
    const propertyName = gqlResult.definitions[0].variableDefinitions.length
      ? gqlResult.definitions[0].variableDefinitions[0].variable.name.value
      : gqlResult.definitions[0].name.value;
    return new Promise((resolve, reject) => {
      func({ variables: { [propertyName]: data } })
        .then((response) =>
          resolve({
            ...response,
            data: response.data && getPureData(response.data, routes)
          })
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  return [
    finalFunc,
    { ...mutationResult, data: getPureData(mutationResult.data, routes) }
  ];
};

export { useAryLazyQuery, useAryMutation, useAryQuery };
